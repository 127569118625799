<template>
  <div class="row child-component">

    <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">

      <div class="d-block row col-lg-8 mx-auto">
        <div class="fieldborder">
          <slot name="label" class="lbltxt">
            <span class="lbltxt" style="margin-left: 11px">
              VENDOR BUSINESS NAME
            </span>
            <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9" version="1.1"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              v-b-tooltip.hover.v-dark.right.html="' This should be the media supplier name that we are placing the order with. Ie: website name, publication or station call letters'
                ">
              <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path
                  d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                  id="info" fill="#00A1D2" fill-rule="nonzero"></path>
              </g>
            </svg>
          </slot>
          <b-form-input autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="36"
            v-model="ProdExpVendor.VendorBusinessName" placeholder="Enter Vendor Business Name"></b-form-input>
        </div>


        <div class="fieldborder row">
          <div class="col-md-6">
            <div>
              <label>IDENTIFICATION NUMBER</label>
              <OmniSelect placeholder="Select Identification Code" name="Media" :showLabel="false"
                :options="Identificationnumberslist" label="IDENTIFICATION NUMBER" @input="IDNumberchange"
                v-model="ProdExpVendor.IdentificationType" />
            </div>

          </div>
          <div class="col-md-6" v-show="ProdExpVendor.IdentificationType">
            <the-mask type="text" :tokens="{ '#': { pattern: /\d/ } }" :mask="['##', '##-########']"
              style="margin-top:1.5rem;" class="form-control" v-if="IsUSTaxIDSelected"
              @focus="ResetDirty(ProdExpVendor.IdentificationType)" @blur="SetDirty(ProdExpVendor.IdentificationType)"
              autocomplete="off" id="ustaxid" v-model="ProdExpVendor[ProdExpVendor.IdentificationType]"
              :placeholder="'Enter US Tax ID'"></the-mask>
            <!-- <b-form-input style="margin-top:1.5rem;" v-if="IsUSTaxIDSelected" @focus="ResetDirty(ProdExpVendor.IdentificationType)" @blur="SetDirty(ProdExpVendor.IdentificationType)" autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="GetMaxLength(ProdExpVendor.IdentificationType)" v-model="ProdExpVendor[ProdExpVendor.IdentificationType]" v-numericOnly :placeholder="'Enter '+ProdExpVendor.IdentificationType"></b-form-input> -->
            <!-- <b-form-input style="margin-top:1.5rem;" v-else-if="IsSocialSecurityNumberSelected" @focus="ResetDirty(ProdExpVendor.IdentificationType)" @blur="SetDirty(ProdExpVendor.IdentificationType)" autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="GetMaxLength(ProdExpVendor.IdentificationType)" v-model="ProdExpVendor[ProdExpVendor.IdentificationType]" v-numericOnly :placeholder="'Enter '+ProdExpVendor.IdentificationType"></b-form-input> -->
            <the-mask type="text" :maxlength="11" :tokens="{ '#': { pattern: /\d/ } }"
              :mask="['###', '###-##', '###-##-####']" v-else-if="IsSocialSecurityNumberSelected"
              style="margin-top:1.5rem;" class="form-control" @focus="ResetDirty(ProdExpVendor.IdentificationType)"
              @blur="SetDirty(ProdExpVendor.IdentificationType)" autocomplete="off" id="socialsecurity"
              v-model="ProdExpVendor[ProdExpVendor.IdentificationType]"
              :placeholder="'Enter Social Security Number'"></the-mask>

            <b-form-input style="margin-top:1.5rem;" v-else @focus="ResetDirty(ProdExpVendor.IdentificationType)"
              @blur="SetDirty(ProdExpVendor.IdentificationType)" autocomplete="off" id="cilentcode" class="omniipclass"
              v-model="ProdExpVendor[ProdExpVendor.IdentificationType]"
              :placeholder="'Enter ' + ProdExpVendor.IdentificationType"></b-form-input>

            <div class="error-message-format"
              v-if="ProdExpVendor.IdentificationType && $v.ProdExpVendor[ProdExpVendor.IdentificationType].$dirty">
              <span v-show="!$v.ProdExpVendor[ProdExpVendor.IdentificationType].required">
                Required field
              </span>
            </div>
          </div>
        </div>


        <div v-show="ProdExpVendor.IdentificationType">
          <label for="axlocatoin" class="lbltxt">{{ AttachmentLabel }}</label>
          <span>
            <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9" version="1.1"
              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              v-b-tooltip.hover.v-dark.right.html="' Attachment of a completed W-9 form or Foreign W-8 form signed and dated within the last year, required for any change in ownership'
                ">
              <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path
                  d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                  id="info" fill="#00A1D2" fill-rule="nonzero"></path>
              </g>
            </svg>
          </span>
          <vue-dropzone v-on:vdropzone-mounted="mountedIdentitificationDropzone" :duplicateCheck="true"
            ref="identificationDropzone" id="identificationDropzone" :useCustomSlot="true" class="customdropzone"
            :include-styling="false" :options="dropzoneOptions"
            v-on:vdropzone-file-added="IdentificationUploadedToDropzone"
            v-on:vdropzone-removed-file="IdentificationRemovedFromDropzone">
            <div class="dropzone-custom-content">
              <p class="Drag-and-drop-file-h">
                <b-img style="
                        margin-top: -7px !important;
                        font-family: 'Open Sans';
                      " />
                Drag and drop file here or
                <span class="draganddropspan"> browse </span>

              </p>
            </div>
          </vue-dropzone>
        </div>
        <div class="fieldborder mt-2 pb-0 mb-n2">
            <b-form-group label="" class="timeonly-text">
                <label>Is this for former employee Cobra reimbursement?</label>
                <b-form-radio-group style="margin-left:10px;" class="omniipclass" id="FormerEmployeeReimbursement"
                    v-model="ProdExpVendor.FormerEmployeeReimbursement" name="FormerEmployeeReimbursement">
                    <b-form-radio value="true">YES</b-form-radio>
                    <b-form-radio value="false">NO</b-form-radio>
                </b-form-radio-group>
            </b-form-group>
        </div>
        <div class="fieldborder mt-2 pb-0 mb-n2">
            <b-form-group label="" class="timeonly-text">
                <label>Should this vendor receive a 1099?</label>
                <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                  v-b-tooltip.hover.v-dark.right.html="'Any vendor that is an Individual/Sole Proprietor, Limited Liability Company (LLCs), Partnership, or Trust/Estate should receive a 1099. Cobra Reimbursements, Corporations, Government Agencies or a 501C Non-Profits will not receive a 1099.'
                    ">
                  <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <path
                      d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z"
                      id="info" fill="#00A1D2" fill-rule="nonzero"></path>
                  </g>
                </svg>
                <b-form-radio-group style="margin-left:10px;" class="omniipclass" id="IsVendorReceive1099"
                    v-model="ProdExpVendor.IsVendorReceive1099" name="IsVendorReceive1099">
                    <b-form-radio value="YES">YES</b-form-radio>
                    <b-form-radio value="NO">NO</b-form-radio>
                </b-form-radio-group>
            </b-form-group>
        </div>
        <div class="fieldborder pb-0 mb-n2" v-if="ProdExpVendor.IsVendorReceive1099 == 'YES'">
            <b-form-group label="" class="timeonly-text">
                <label style="line-height: normal;">Should the 1099 be issued to the same name and EIN as the vendor we are remitting payment to?</label>
                <b-form-radio-group style="margin-left:10px;" class="omniipclass" id="IsIssuedNameEIN"
                    v-model="ProdExpVendor.IsIssuedNameEIN" name="IsIssuedNameEIN">
                    <b-form-radio value="YES">YES</b-form-radio>
                    <b-form-radio value="NO">NO</b-form-radio>
                </b-form-radio-group>
            </b-form-group>
        </div>
        <div v-if="ProdExpVendor.IsIssuedNameEIN == 'NO'">
          <div class="fieldborder">
            <label style="line-height:normal">Please confirm the name of the vendor that the 1099 should be issued to (please note that this name must appear on the first line of the vendors W-9).</label>
            <b-form-input autocomplete="off" id="IssuedName" class="omniipclass" v-model="ProdExpVendor.IssuedName" placeholder="Enter Name"></b-form-input>
          </div>
          <div class="fieldborder">
            <label>Please confirm the TIN for this Vendor</label>
            <b-form-input autocomplete="off" id="VendorTIN" class="omniipclass" v-model="ProdExpVendor.VendorTIN" placeholder="Enter TIN for Vendor"></b-form-input>
          </div>
        </div>
        <div class="fieldborder pt-2">
            <label>VENDOR REMITTANCE NAME</label>
            <b-form-input @focus="ResetDirty('VendorRemittanceName')" @blur="SetDirty('VendorRemittanceName')"
                autocomplete="off" id="VendorRemittanceName" class="omniipclass"
                v-model="ProdExpVendor.VendorRemittanceName" placeholder="Enter Vendor Remittance Name"
                :maxlength="30"></b-form-input>
        </div>
        <div class="fieldborder pt-2">
            <label>COUNTRY</label>
            <OmniSelect class="pt-0 omniipclass omniindexclass" placeholder="Select Country"
                name="RemittanceCountry" :showLabel="false" v-model="ProdExpVendor.RemittanceCountry" :options="CountryList">
            </OmniSelect>
        </div>
        <div class="fieldborder pt-2">
            <label>STREET ADDRESS LINE 1 </label>
            <b-form-input @focus="ResetDirty('StreetAddressLine1')" @blur="SetDirty('StreetAddressLine1')"
                autocomplete="off" id="StreetAddressLine1" class="omniipclass"
                v-model="ProdExpVendor.StreetAddressLine1" placeholder="Enter Street Address Line1"
                :maxlength="26"></b-form-input>
            <div class="error-message-format"
                v-if="$v.ProdExpVendor.StreetAddressLine1.$dirty && !$v.ProdExpVendor.StreetAddressLine1.required">
                Required field
            </div>
        </div>
        <div class="fieldborder pt-2">
            <label>STREET ADDRESS LINE 2 </label>
            <b-form-input @focus="ResetDirty('StreetAddressLine2')" @blur="SetDirty('StreetAddressLine2')"
                autocomplete="off" id="StreetAddressLine2" class="omniipclass"
                v-model="ProdExpVendor.StreetAddressLine2" placeholder="Enter Street Address Line2"
                :maxlength="30"></b-form-input>
        </div>
        <div class="fieldborder pt-2" v-if="IsCountryUSA">
            <label for="city" class="lbltxt" v-if="IsCountryUSA">CITY</label>
            <b-form-input @focus="ResetDirty('RemittanceCity')" @blur="SetDirty('RemittanceCity')" autocomplete="off"
                id="billingCity" class="omniipclass" v-if="IsCountryUSA" v-model="ProdExpVendor.RemittanceCity"
                placeholder="Enter City" :maxlength="19"></b-form-input>
            <div class="error-message-format"
                v-if="$v.ProdExpVendor.RemittanceCity.$dirty && !$v.ProdExpVendor.RemittanceCity.required">
                Required field
            </div>
        </div>
        <div class="row fieldborderomniipclass pt-2" v-if="IsCountryUSA">
            <div class="col-md-8">
                <label v-if="IsCountryUSA" for="RemittanceState" class="lbltxt">State</label>
                <OmniSelect  :showLabel="false" class="pt-0 omniindexclass omniindexclass" v-if="IsCountryUSA" placeholder="Select State" name="RemittanceState" :showSearch="true" v-model="ProdExpVendor.RemittanceState" :options="StateList">
                </OmniSelect>
            </div>
            <div class="col-md-4">
                <label v-if="IsCountryUSA" for="RemittanceZipCode" class="lbltxt">ZIP CODE</label>
                <b-form-input autocomplete="off" id="RemittanceZipCode" class="" :minlength="5" v-if="IsCountryUSA" :maxlength="5" v-bind:class="{
                        redtextbox: (ValidationTextBox || !$v.ProdExpVendor.RemittanceZipCode.numeric) && $v.ProdExpVendor.RemittanceZipCode.$dirty,
                        notredtextbox: !ValidationTextBox && $v.ProdExpVendor.RemittanceZipCode.numeric,
                    }" @blur="SetDirty('RemittanceZipCode')" @focus="ResetDirty('RemittanceZipCode')"
                    @keyup="ValidateZipCode()" v-model="ProdExpVendor.RemittanceZipCode"
                    placeholder="Enter Zip Code"></b-form-input>
                <div v-show="ValidationTextBox && $v.ProdExpVendor.RemittanceZipCode.$dirty" class="error-message-format">
                    Input must be 5 characters
                </div>
                <div v-show="!$v.ProdExpVendor.RemittanceZipCode.numeric && $v.ProdExpVendor.RemittanceZipCode.$dirty"
                    style="width:135px;" class="error-message-format">
                    This field can only contain numerical values
                </div>
            </div>
        </div>
        <div class="mx-auto mt-2">
            <b-form-group>
                <label>
                    Vendor Email
                </label>
                <b-form-input type="email" @focus="ResetDirty('RemittanceEmail')"
                    @blur="SetDirty('RemittanceEmail')" autocomplete="off" placeholder="Enter Vendor Email"
                    v-model="ProdExpVendor.RemittanceEmail" ref="email"></b-form-input>
                <div class="error-message-format" v-if="$v.ProdExpVendor.RemittanceEmail.$dirty">
                    <span v-show="!$v.ProdExpVendor.RemittanceEmail.required">
                        Required field
                    </span>
                    <span v-show="!$v.ProdExpVendor.RemittanceEmail.email">
                        Please enter a valid email address (eg. test@finsys.com)
                    </span>

                </div>
            </b-form-group>
        </div>
        <div class="fieldborder" style="margin-top:10px">
          <label>Who at the vendor provided this information? </label>
          <br />
          <label>Name </label>
          <b-form-input autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="36"
            v-model="ProdExpVendor.InfoFromName" placeholder="Name"></b-form-input>
          <label>Title </label>
          <b-form-input autocomplete="off" id="cilentcode" class="omniipclass" :maxlength="36"
            v-model="ProdExpVendor.InfoFromTitle" placeholder="Title"></b-form-input>
          <label>Phone Number </label>
          <the-mask type="text" :tokens="{ '#': { pattern: /\d/ } }" :mask="['##', FormatPhoneNumber]"
            style="margin-top:1px;" class="form-control" @focus="ResetDirty(ProdExpVendor.InfoFromPhone)"
            @blur="SetDirty(ProdExpVendor.InfoFromPhone)" placeholder="Phone Number" autocomplete="off" id="cilentcode" :maxlength="24"
            v-model="ProdExpVendor.InfoFromPhone"></the-mask>
        </div>

        <b-form-group label="How was this information obtained?" class="mb-n3 timeonly-text">
          <div class="form-group" style="margin-left: 14px">
            <div class="text-left" style="margin-top: 11px">
              <b-form-checkbox v-model="ProdExpVendor.InfoFromSourceIsVerbal" value="true" label="dsad" inline>
                Verbal
              </b-form-checkbox>
            </div>
          </div>

          <div class="form-group" style="margin-left: 14px">
            <div class="text-left" style="margin-top: 11px">
              <b-form-checkbox v-model="ProdExpVendor.InfoFromSourceIsWritten" value="true" inline>
                Written
              </b-form-checkbox>
            </div>
          </div>
        </b-form-group>

        <div class="fieldborder mb-n1">
          <b-form-group class="mb-1" v-if="InfoFromSourceDisplayVerbal">
            <div>
              <label>Verbal </label>
              <OmniSelect placeholder="Select Verbal" name="Media" :showLabel="false"
                :options="InfoFromSourceVerbalOptions" label="Select Verbal"
                v-model="ProdExpVendor.InfoFromSourceVerbal" />
            </div>
          </b-form-group>
        </div>

        <b-form-group v-if="InfoFromSourceDisplayWritten">
          <div>
            <label>Written</label>
            <OmniSelect placeholder="Select Written" name="Written" :showLabel="false"
              :options="InfoFromSourceWrittenOptions" label="Select Written"
              v-model="ProdExpVendor.InfoFromSourceWritten" />
          </div>
          <div class="fieldborder" style="margin-top: 10px">
            <label>Attach Written</label>
            <vue-dropzone v-on:vdropzone-mounted="mountedWrittenDropzone" :duplicateCheck="true"
              ref="DropzoneWrittenSource" id="DropzoneWrittenSource" :useCustomSlot="true" class="customdropzone"
              :include-styling="false" :options="dropzoneOptions" v-on:vdropzone-file-added="UploadedtoDropzoneSource"
              v-on:vdropzone-removed-file="RemovedFromDropzoneSource">
              <div class="dropzone-custom-content">
                <p class="Drag-and-drop-file-h">
                  <b-img style="
                        margin-top: -7px !important;
                        font-family: 'Open Sans';
                      " />
                  Drag and drop file here or
                  <span class="draganddropspan"> browse </span>

                </p>
              </div>
            </vue-dropzone>
          </div>
        </b-form-group>

        <b-form-group label="Are there invoices already obtained that require immediate payment?" class="mt-1 timeonly-text">
          <OmniSelect placeholder="Are there invoices already obtained that require immediate payment?"
            name="InvoicesImmediatePayment" :showLabel="false" :options="InvoicesImmediatePaymentOptions" label=""
            v-model="ProdExpVendor.InvoicesImmediatePayment" />
        </b-form-group>
        <div v-if="ProdExpVendor.InvoicesImmediatePayment == 'Yes'">
          <label for="axlocatoin" class="lbltxt">Invoice Copy</label>
          <vue-dropzone v-on:vdropzone-mounted="mountedInvoiceCopiesDropzone" :duplicateCheck="true"
            ref="InvoiceCopiesDropzone" id="InvoiceCopiesDropzone" :useCustomSlot="true" class="customdropzone"
            :include-styling="false" :options="dropzoneOptions"
            v-on:vdropzone-file-added="InvoiceCopiesUploadedToDropzone"
            v-on:vdropzone-removed-file="InvoiceCopiesRemovedFromDropzone">
            <div class="dropzone-custom-content">
              <p class="Drag-and-drop-file-h">
                <b-img style="
                        margin-top: -7px !important;
                        font-family: 'Open Sans';
                      " />
                Drag and drop file here or
                <span class="draganddropspan"> browse </span>

              </p>
            </div>
          </vue-dropzone>
        </div>

        <br>
        <br>
        <br>
        <br>
      </div>
    </div>
    <div class="form-group pt-60 text-right  footer-buttons" style="padding-right:96px !important;">
      <button class="btn btn-link text-uppercase" @click="Cancel()">
        CANCEL
      </button>
      <button class="text-uppercase btn custom-button btn-primary" @click="Previous()">
        BACK
      </button>
      <button class="text-uppercase btn custom-button btn-primary" @click="Next()" :disabled="this.$v.$invalid || !IsVerbalOrWrittenChecked">
        NEXT
      </button>
    </div>
  </div>
</template>

<style scoped>
.agencycontactwithtopmargin {
  margin-top: -30px !important;
}

.agencycontactwithnottopmargin {
  margin-top: -4px !important;
}

.withtopmargin {
  margin-top: -22px !important;
}

.withnottopmargin {
  margin-top: -4px !important;
}

.OMCLocator {
  width: 498px;
  margin-left: -2px;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: .8;
  letter-spacing: normal;
  margin-bottom: 6px;
  padding-left: 12px;
  color: #00a1d2;
  text-transform: uppercase;
}

.redtextbox {
  height: 35px !important;
  border: 1px solid #df5678 !important;
}

.notredtextbox {
  border: none !important;
}

.fieldborder {
  padding-bottom: 7px !important;
}

.customdropzone {
  height: 100% !important;
  border-radius: 8px !important;
  border: dashed 2px #e9eef0 !important;
  background-color: #ffffff !important;
  cursor: pointer !important;
}

.Drag-and-drop-file-h {
  margin-left: 18px !important;
  margin-top: 14px !important;
  width: 387px !important;
  height: 10px !important;
  font-family: "Open Sans" !important;
  font-size: 14px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 0.71 !important;
  letter-spacing: normal !important;
  /* color: #a2a9ad !important; */
}


.zipcodevalidationmsg {
  /* margin-top: -20px !important; */
  width: 157px !important;
  font-size: 10px !important;
  color: #df5678 !important;
  font-family: "Open Sans" !important;
  font-size: 11px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: italic !important;
  line-height: 0.8 !important;
  letter-spacing: normal !important;
  text-align: left !important;
}

.error-message1 {
  height: 14px !important;
  font-size: 10px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #df5678 !important;
}

.error-message {
  height: 14px !important;
  font-size: 10px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #df5678 !important;
}

.draganddropspan {
  color: #00a1d2;
}
</style>

<script>
import {
  mapGetters
} from "vuex";
import { required, maxLength, requiredIf, numeric, minLength, helpers } from "vuelidate/lib/validators";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { TheMask } from 'vue-the-mask';
import service from "../../../services/Dropdowns";
const EmailRegEx = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[x01-x08x0bx0cx0e-x1fx21x23-x5bx5d-x7f]|[x01-x09x0bx0cx0e-x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9]{2,}(?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[x01-x08x0bx0cx0e-x1fx21-x5ax53-x7f]|\\[x01-x09x0bx0cx0e-\x7f])+)\])$/
const EmailWithoutCase = helpers.regex('EmailWithoutCase', EmailRegEx)
export default {
  validations: {
    ProdExpVendor: {
      VendorBusinessName: {
        required,
        maxLength: maxLength(36)
      },
      InfoFromName: { required },
      InfoFromTitle: { required },
      InfoFromPhone: {
        required,
        minLength: minLength(10)
      },
      IdentificationType: {
        required: required,
      },
      InvoicesImmediatePayment: { required },
      InfoFromSourceIsVerbal: {
          required: requiredIf(function () {
              return !this.ProdExpVendor.InfoFromSourceIsWritten;
          }),
      },
      InfoFromSourceIsWritten: {
          required: requiredIf(function () {
              return !this.ProdExpVendor.InfoFromSourceIsVerbal;
          }),
      },
      InfoFromSourceVerbal: {
          required: requiredIf(function (model) {
              return model.InfoFromSourceIsVerbal == true || model.InfoFromSourceIsVerbal == "true";
          }),
      },
      InfoFromSourceWritten: {
          required: requiredIf(function (model) {
              return model.InfoFromSourceIsWritten == true || model.InfoFromSourceIsWritten == "true";
          }),
      },
      SetupInfoSource: {
          minLength: minLength(1),
            required: requiredIf(function (model) {
            return model.InfoFromSourceIsWritten == "true" || model.InfoFromSourceIsWritten == true;
        })
      },
      USTaxID: {
        required: requiredIf(function (model) {
          return model.IdentificationType == "USTaxID";
        }),
        numeric,
        minLength: minLength(9)
      },
      SocialSecurityNumber: {
        required: requiredIf(function (model) {
          return model.IdentificationType == "SocialSecurityNumber";
        }),
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(9),
      },
      Foreign: {
        required: requiredIf(function (model) {
          return model.IdentificationType == "Foreign";
        })
      },
      IdentificationFiles: {
        minLength: minLength(1),
        required: requiredIf(function (model) {
          return model.IdentificationType == "USTaxID" || model.IdentificationType == "SocialSecurityNumber" || model.IdentificationType == "Foreign";
        })
      },
      FormerEmployeeReimbursement: {
        required: required,
      },
      IsVendorReceive1099: {
        required: required,
      },
      IsIssuedNameEIN: {
        required: requiredIf(function (model) {
          return model.IsVendorReceive1099 == "YES" || model.IsVendorReceive1099 == true;
        })
      },
      IssuedName: {
        required: requiredIf(function (model) {
          return model.IsIssuedNameEIN == "NO" || model.IsIssuedNameEIN == false;
        })
      },
      VendorTIN: {
        required: requiredIf(function (model) {
          return model.IsIssuedNameEIN == "NO" || model.IsIssuedNameEIN == false;
        })
      },
      InvoiceCopies: {
        minLength: minLength(1),
        required: requiredIf(function (model) {
          return model.InvoicesImmediatePayment == "Yes";
        })
      },
      VendorRemittanceName: {
          required: required
      },
      RemittanceCountry: {
          required: required
      },
      StreetAddressLine1: {
          required: required
      },
      RemittanceCity: {
          required: requiredIf(function (model) {
              return model.RemittanceCountry == this.CountryUnitedStatesValue;
          }),
      },
      RemittanceState: {
          required: requiredIf(function (model) {
              return model.RemittanceCountry == this.CountryUnitedStatesValue;
          }),
      },
      RemittanceZipCode: {
          required: requiredIf(function (model) {
              return model.RemittanceCountry == this.CountryUnitedStatesValue;
          }),
          numeric,
      },
      RemittanceEmail: {
          required: required,
          email: EmailWithoutCase
      },
    }
  },

  data() {
    return {
      IsIdentificationFilesLoadedInEdit: false,
      IsWrittenFilesLoadedInEdit: false,
      IsInvoiceCopiesFilesLoadedInEdit: false,
      writtenAttachmentFile: 0,
      ddl: 0,
      Identificationnumberslist: [{
        label: "US TAX ID",
        value: "USTaxID"
      },
      {
        label: "Foreign",
        value: "Foreign"
      },
      {
        label: "Social Security",
        value: "SocialSecurityNumber"
      },
      ],
      InfoFromSourceVerbalOptions: [
        { label: "In Person", value: "In Person" },
        { label: "Phone", value: "Phone" }
      ],
      InfoFromSourceWrittenOptions: [
        { label: "Teams Chat", value: "Teams Chat" },
        { label: "Email", value: "Email" }
      ],
      InvoicesImmediatePaymentOptions: [
        { label: "Yes", value: "Yes" },
        { label: "No", value: "No" }
      ],
      dropzoneOptions: {
        closeFilterIcon: require("@/assets/Icons/add-client.svg"),
        previewTemplate: this.template(),
        url: `${process.env.VUE_APP_API_ROOT}api/v1/ProfileUnlock/ProfileUpload`,
        uploadMultiple: true,
        maxFiles: 5,
        minFiles: 1,
        maxFilesize: 10,
        addRemoveLinks: false,
        parallelUploads: 5,
        createImageThumbnails: false,
        autoProcessQueue: false,
        init: function () {
          this.on("addedfile", function (file) {
            if (this.files.length > 5) {
              this.removeFile(file);
              window.alert('Maximum File Upload limit is 5!');
            }
          });
        },
      },
      CountryList: [],
      StateList: [],
      CountryUnitedStatesValue: "USA - United States",
      ValidationTextBox: false,
    }
  },
  components: {
    vueDropzone: vue2Dropzone, TheMask
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    isDraft: {
      type: Boolean,
      required: false
    }
  },
  activated() {
    this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
  },
  watch: {
    "ProdExpVendor.InfoFromPhone": function() {
        if (this.ProdExpVendor.InfoFromPhone.length > 10) {
            this.FormatPhoneNumber = true;
        }
        else {
            this.FormatPhoneNumber = false;
        }
    },
    "ProdExpVendor.IsEditDataLoaded": function (val) {
        if (this.IsEdit == true && val == true) {
            if (this.IsIdentificationFilesLoadedInEdit == false) {
                this.ManuallyUploadFilesIdentification(this.ProdExpVendor.IdentificationFiles);
                this.$set(this.ProdExpVendor, "IdentificationFiles", this.GetIDFilesObject());
            }
            if (this.IsWrittenFilesLoadedInEdit == false) {
                this.ManuallyUploadFilesWritten(this.ProdExpVendor.SetupInfoSource);
                this.$set(this.ProdExpVendor, "SetupInfoSource", this.GetFilesObjectSource());
            }
            if (this.IsInvoiceCopiesFilesLoadedInEdit == false) {
                this.ManuallyUploadFilesInvoiceCopies(this.ProdExpVendor.InvoiceCopies);
                this.$set(this.ProdExpVendor, "InvoiceCopies", this.GetIDInvoiceCopiesObject());
            }
        }
    },
    "ProdExpVendor.ID": function(val) {
      this.id = parseFloat(val);
      if (val > 0) {
          this.RenderUploadedFiles();
          this.ManuallyUploadFiles(this.ProdExpVendor.IdentificationFiles);
      }
    },
    "ProdExpVendor.InfoFromSourceIsVerbal": function (val) {
        if(val == false || val == 'false') {
            this.ProdExpVendor.InfoFromSourceVerbal=null;
        }
    },
    "ProdExpVendor.InfoFromSourceIsWritten": function (val) {
        if(val == false ||  val == 'false') {
            this.ProdExpVendor.InfoFromSourceWritten=null;
        }
    },
    "ProdExpVendor.IdentificationType": function (val) {
        if (val) {
            let identificationIndex = this.Identificationnumberslist.findIndex(newVal => {
                return newVal.value == val;
            })
            if (identificationIndex > -1) {
                this.$set(this.ProdExpVendor, "IdentificationName", this.Identificationnumberslist[identificationIndex].label);
            }
            else {
                this.$set(this.ProdExpVendor, "IdentificationName", '');
            }
        }
    },
    "ProdExpVendor.IsVendorReceive1099": function (val) {
      if (val) {
        this.ProdExpVendor.IsIssuedNameEIN = null;
      }
    },
    "ProdExpVendor.IsIssuedNameEIN": function (val) {
      if (val) {
        this.ProdExpVendor.IssuedName = "";
        this.ProdExpVendor.VendorTIN = "";
      }
    },
    "ProdExpVendor.RemittanceCountry": function (val) {
        if (val) {
            this.ProdExpVendor.RemittanceCity = '';
            this.ProdExpVendor.RemittanceState = '';
            this.ProdExpVendor.RemittanceZipCode = '';
        }
    },
  },

  computed: {
    FormatPhoneNumber() {
        const infoFromPhone = this.ProdExpVendor.InfoFromPhone || '';
        return infoFromPhone.length > 10 ? '####################' : '1-###-###-#####';
    },
    IsVerbalOrWrittenChecked() {
        return this.InfoFromSourceDisplayWritten || this.InfoFromSourceDisplayVerbal;
    },
    ...mapGetters('prodexpvendor', {
      ProdExpVendor: 'ProdExpVendor',
    }),
    InfoFromSourceDisplayVerbal() {
      return this.ProdExpVendor.InfoFromSourceIsVerbal;
    },
    InfoFromSourceDisplayWritten() {
      return this.ProdExpVendor.InfoFromSourceIsWritten;
    },
    IsUSTaxIDSelected() {
      return this.ProdExpVendor.IdentificationType == 'USTaxID';
    },
    IsSocialSecurityNumberSelected() {
      return this.ProdExpVendor.IdentificationType == 'SocialSecurityNumber';
    },
    AttachmentLabel() {
      return this.ProdExpVendor.IdentificationType == 'SocialSecurityNumber' || this.ProdExpVendor.IdentificationType == 'USTaxID' ? 'W-9 Attachment' : 'W-8BEN/W-8BEN-E Attachment';
    },
    IsEdit() {
      return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
    },
    IsCountryUSA() {
        return this.ProdExpVendor.RemittanceCountry != null ? this.ProdExpVendor.RemittanceCountry == this.CountryUnitedStatesValue : null;
    },
  },
  mounted() {
      this.LoadDropdownValues();
  },
  methods: {
    RenderUploadedFiles() {
        this.$store
            .dispatch("prodexpvendor/RenderUploadedFiles", {
            id: parseInt(this.id),
            category: "IdentificationFiles",
            })
            .then((res) => {
            this.ManuallyUploadFiles(res.data.Files);
            this.$set(
                this.ProdExpVendor,
                "IdentificationFiles",
                this.GetFilesObject()
            );
            })
            .catch((ex) => {
            if (ex.response) {
                this.SetErrorMessage("Error while getting files", ex.response.data);
            } else {
                //this.SetErrorMessage("Error while getting files", ex);
            }
            });
        },
    IDNumberchange() {
      this.ProdExpVendor.USTaxID = "";
      this.ProdExpVendor.SocialSecurityNumber = "";
      this.ProdExpVendor.Foreign = "";
      this.$refs.identificationDropzone.removeAllFiles()
    },
    mountedIdentitificationDropzone() {
      if (this.ProdExpVendor.IsEditDataLoaded == true && this.IsIdentificationFilesLoadedInEdit == false) {
          this.ManuallyUploadFilesIdentification(this.ProdExpVendor.IdentificationFiles);
          this.$set(this.ProdExpVendor, "IdentificationFiles", this.GetIDFilesObject());
      }
    },
    mountedInvoiceCopiesDropzone() {
      if (this.ProdExpVendor.IsEditDataLoaded == true && this.IsInvoiceCopiesFilesLoadedInEdit == false) {
          this.ManuallyUploadFilesInvoiceCopies(this.ProdExpVendor.InvoiceCopies);
          this.$set(this.ProdExpVendor, "InvoiceCopies", this.GetIDInvoiceCopiesObject());
      }
    },
     mountedWrittenDropzone() {
      if (this.ProdExpVendor.IsEditDataLoaded == true && this.IsWrittenFilesLoadedInEdit == false) {
          this.ManuallyUploadFilesWritten(this.ProdExpVendor.SetupInfoSource);
          this.$set(this.ProdExpVendor, "SetupInfoSource", this.GetFilesObjectSource());
      }
    },
    ManuallyUploadFilesIdentification(files) {
        if (files != null && files.length > 0) {
            files.forEach((fileUploaded) => {
                let file = {
                    size: fileUploaded.Length,
                    name: fileUploaded.Name,
                    type: fileUploaded.Extension,
                };
                var url = fileUploaded.FullName;
                this.$refs.identificationDropzone.manuallyAddFile(file, url);
            });

        }
        this.IsIdentificationFilesLoadedInEdit = true;
    },
    ManuallyUploadFilesWritten(files) {
            if (files != null && files.length > 0) {
                files.forEach((fileUploaded) => {
                    let file = {
                        size: fileUploaded.Length,
                        name: fileUploaded.Name,
                        type: fileUploaded.Extension,
                    };
                    var url = fileUploaded.FullName;
                    this.$refs.DropzoneWrittenSource.manuallyAddFile(file, url);
                });
            }
            this.IsWrittenFilesLoadedInEdit = true;
        },
    ManuallyUploadFilesInvoiceCopies(files) {
        if (files != null && files.length > 0) {
            files.forEach((fileUploaded) => {
                let file = {
                    size: fileUploaded.Length,
                    name: fileUploaded.Name,
                    type: fileUploaded.Extension,
                };
                var url = fileUploaded.FullName;
                this.$refs.InvoiceCopiesDropzone.manuallyAddFile(file, url);
            });

        }
        this.IsInvoiceCopiesFilesLoadedInEdit = true;
    },
    IdentificationUploadedToDropzone() {
      this.$set(this.ProdExpVendor, "IdentificationFiles", this.GetIDFilesObject());
    },
    IdentificationRemovedFromDropzone() {
      this.$set(this.ProdExpVendor, "IdentificationFiles", this.GetIDFilesObject());
    },
    InvoiceCopiesUploadedToDropzone() {
      this.$set(this.ProdExpVendor, "InvoiceCopies", this.GetIDInvoiceCopiesObject());
    },
    InvoiceCopiesRemovedFromDropzone() {
      this.$set(this.ProdExpVendor, "InvoiceCopies", this.GetIDInvoiceCopiesObject());
    },
    UploadedtoDropzoneSource() {
      this.writtenAttachmentFile = this.$refs.DropzoneWrittenSource.dropzone.files.length;
      this.$set(this.ProdExpVendor, "SetupInfoSource", this.GetFilesObjectSource());
    },
    RemovedFromDropzoneSource() {
      this.writtenAttachmentFile = this.$refs.DropzoneWrittenSource.dropzone.files.length;
      this.$set(this.ProdExpVendor, "SetupInfoSource", this.GetFilesObjectSource());
    },
    GetIDFilesObject() {
        let files = [];
        if (this.$refs.identificationDropzone.dropzone.files && this.$refs.identificationDropzone.dropzone.files.length > 0) {
            let filesExists = this.$refs.identificationDropzone.dropzone.files;
            for (let i = 0; i < filesExists.length; i++) {
                files.push({
                    name: filesExists[i].name, file: filesExists[i]
                });
            }
        }
        return files;
    },
    GetFilesObjectSource() {
      let files = [];
      if (typeof this.$refs.DropzoneWrittenSource != "undefined" &&
        typeof this.$refs.DropzoneWrittenSource.dropzone != "undefined" &&
        this.$refs.DropzoneWrittenSource.dropzone.files.length > 0) {
        let filesExists = this.$refs.DropzoneWrittenSource.dropzone.files;
        for (let i = 0; i < filesExists.length; i++) {
          files.push({
            name: filesExists[i].name, file: filesExists[i]
          });
        }
      }
      return files;
    },
    GetIDInvoiceCopiesObject() {
        let files = [];
        if (this.$refs.InvoiceCopiesDropzone.dropzone.files && this.$refs.InvoiceCopiesDropzone.dropzone.files.length > 0) {
            let filesExists = this.$refs.InvoiceCopiesDropzone.dropzone.files;
            for (let i = 0; i < filesExists.length; i++) {
                files.push({
                    name: filesExists[i].name, file: filesExists[i]
                });
            }
        }
        return files;
    },
    SetDirty(propertyName) {
      this.$v.ProdExpVendor[propertyName].$touch();

    },
    ResetDirty(propertyName) {
      this.$v.ProdExpVendor[propertyName].$reset();
    },
    Next() {
      this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
      if (this.id) {
        this.$router.push({
          name: "Production Expense Vendor Banking Info",
          params: {
            id: this.id,
            isDraft: this.isDraft
          }
        });
      } else {
        this.$router.push({
          name: "Production Expense Vendor Banking Info"
        });
      }
    },
    Previous() {
      if (this.id) {
        this.$router.push({
              name: "Production Expense Vendor Attestation",
          params: {
            id: this.id,
            isDraft: this.isDraft
          }
        });
      } else {
        this.$router.push({
            name: "Production Expense Vendor Attestation",
        });
      }
    },
    Cancel() {
      this.$router.push("/VendorRequests");
    },
    template: function () {
      return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details" style='margin-right:79px !important;'>
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
    },
    LoadDropdownValues() {
        this.LoadStates();
        service
            .getEntityRecords("Markets")
            .then((res) => {
                if (res.data && res.data.length > 0) {
                    this.CountryList = res.data.sort((a, b) =>
                        a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                    );
                    let indexUSA = this.CountryList.findIndex(
                        (i) => i.label == this.CountryUnitedStatesValue
                    );
                    let indexCanada = this.CountryList.findIndex(
                        (i) => i.label == "CAN - Canada"
                    );
                    if (indexCanada > -1)
                        this.CountryList.unshift(
                            this.CountryList.splice(indexCanada, 1)[0]
                        );
                    if (indexUSA > -1)
                        this.CountryList.unshift(this.CountryList.splice(indexUSA, 1)[0]);
                    this.CountryList.forEach(element => { element.value = element.label;});
                }
            })
            .catch((err) => {
                alert(err);
            });
    },
    async LoadStates() {
        service
            .getEntityRecords("States")
            .then((res) => {
                this.StateList = res.data;
                this.StateList.forEach(element => { element.value = element.label;});
            })
            .catch((err) => {
                alert(err);
            });
    },
    ValidateZipCode() {
        if (this.IsCountryUSA && this.ProdExpVendor.RemittanceZipCode.length != 0 && this.ProdExpVendor.RemittanceZipCode.length < 5) {
            this.ValidationTextBox = true;
        } else {
            this.ValidationTextBox = false;
        }
    },

  }
}
</script>